<template>
  <WsMain>
    <WsCrud
      :modelName="modelName"
      :label="label"
      :fields="fields"
      :liveSearching="true"
      :showFields="showFields"
      :infiniteScroll="true"
      :expandable="false"
      :showExpand="false"
      :pageMode="true"
      :labelInLocale="true"
    ></WsCrud>
  </WsMain>
</template>

<script>
import tulpa_page from "@/__vue2stone_cms/models/tulpa_page_template";
export default {
  metaInfo() {
    return {
      title: this.$t("page_template"),
    };
  },

  data: () => ({
    modelName: "tulpa_page_template",
    label: tulpa_page.label,
    fields: tulpa_page.fields,
    showFields: ["name", "tags"],
  }),
};
</script>